<template>
	<v-layout column fill-height>
		<v-flex shrink>
			<v-layout align-center px-2 row>
				<v-flex mr-1 shrink>
					<w-search-input clearable :label="$t('actions.search')" prepend-inner-icon="search" :value="search" @input="launchSearch($event)" />
				</v-flex>
				<v-flex ml-1 shrink>
					<w-switch class="ma-0" :label="$t('sa.accounting-firms.archived')" :value="deletedAccountingFirm" @input="setDeletedAccountingFirm" />
				</v-flex>
				<v-spacer />
				<w-btn icon="add" @click="openAccountingFirmForm()">{{ $t('sa.accounting-firms.create_accounting_firm') }}</w-btn>
			</v-layout>
		</v-flex>
		<v-flex style="position: relative">
			<v-layout fill-height>
				<div v-show="loadings > 0" class="loader-container">
					<v-progress-circular color="primary" indeterminate size="50" width="6" />
				</div>
				<v-flex scroll-y>
					<v-list two-line>
						<v-list-tile v-for="accountingFirm in accountingFirms" :key="accountingFirm.id" avatar @click="openAccountingFirmForm(accountingFirm)">
							<v-list-tile-content>
								<v-list-tile-title :class="{ 'crossed--text': accountingFirm.deleted_at != null, 'error--text': accountingFirm.deleted_at != null }">{{
									accountingFirm.name
								}}</v-list-tile-title>
							</v-list-tile-content>
						</v-list-tile>
					</v-list>
				</v-flex>
			</v-layout>
		</v-flex>
		<v-flex shrink>
			<v-layout row>
				<v-flex xs12 sm10 offset-sm1 md8 offset-md2 lg6 offset-lg3 xl4 offset-xl4>
					<w-pagination
						:item-name="$tc('sa.accounting-firms.accounting-firms', 2).toLowerCase()"
						:page-number="pagination.page"
						:rows-per-page="pagination.rows"
						:total-items="pagination.total"
						@update:page-number="loadAccountingFirms({ page: $event })"
						@update:rows-per-page="loadAccountingFirms({ perPage: $event })"
					/>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
	name: 'AccountingFirmsList',
	mixins: [SuperAdminModuleGuard],
	data: function () {
		return {
			accountingFirms: [],
			deletedAccountingFirm: false,
			listAccountingFirms: null,
			loadings: 0,
			pagination: {
				rows: 10,
				page: 1,
				total: 0
			},
			search: null,
			searchTimeout: null
		}
	},
	mounted: function () {
		this.loadAccountingFirms()
	},
	methods: {
		launchSearch: function (search) {
			this.search = search

			if (this.searchTimeout) {
				clearTimeout(this.searchTimeout)
				this.searchTimeout = null
			}

			this.searchTimeout = setTimeout(
				function () {
					const query = {
						page: 1
					}
					this.loadAccountingFirms(query)
				}.bind(this),
				1000
			)
		},
		loadAccountingFirms: function (query = {}) {
			this.loadings++

			if (!query.page) {
				query.page = this.pagination.page
			}
			if (!query.perPage) {
				query.perPage = this.pagination.rows
			}
			if (this.search) {
				query.search = this.search
			}
			if (this.deletedAccountingFirm) {
				query.deleted = true
			}

			this.service
				.listAccountingFirms(query)
				.then(({ data: accountingFirms, pagination }) => {
					this.pagination.page = pagination.currentPage
					this.pagination.total = pagination.total

					this.accountingFirms = accountingFirms
				})
				.finally(() => {
					this.loadings--
				})
		},
		openAccountingFirmForm: function (accountingFirm) {
			this.appService.goTo(
				{
					name: 'sa-accounting-firms-form',
					params: {
						accountingFirmId: accountingFirm?.id ?? 'create'
					}
				},
				true
			)
		},
		setDeletedAccountingFirm: function (value) {
			this.deletedAccountingFirm = value
			this.loadAccountingFirms()
		}
	}
}
</script>
